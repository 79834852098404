import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/image';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { CircleChevronRightFill } from 'akar-icons';

const EquipmentFinancePage = () => {
    const titlestrokeani = {
        duration: 1.65,
        ease: [0.19, 1.0, 0.22, 1.0],
        delay: 1,
    };

    return (
        <Layout>
            <Seo
                title="Equipment Finance"
                description="Quokka Finance Equipment Finance options"
            />
            <div className="app-title">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.45,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="title">
                            <h1>Equipment Finance</h1>
                        </div>
                    </motion.div>
                </div>
                <div className="titlestroke">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 1983.911 218.409"
                    >
                        <motion.path
                            d="M7.57 7.57s427.09 139.916 918.524 188.554c355.142 35.149 744.9 22.1 1028.069-128.2"
                            fill="none"
                            stroke="#c2d83d"
                            strokeLinecap="round"
                            strokeWidth="12"
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={titlestrokeani}
                        />
                    </svg>
                    <motion.div
                        initial={{ offsetDistance: '0%', scale: 2.5 }}
                        animate={{ offsetDistance: '100%', scale: 1 }}
                        transition={titlestrokeani}
                    />
                </div>
            </div>
            <div className="app-titlebr">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="apptitle" id="plant">
                            <div className="apptitle__txt">
                                <h2>Plant and Equipment Finance</h2>
                            </div>
                            <div className="apptitle__img">
                                <Image src="excavator.png" alt="" />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="app-plant">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="fwitem">
                            <h1>Finance Lease</h1>
                            <p>
                                This is the most common form of Lease agreement,
                                usually preferred by private companies who like
                                to acquire the asset at the end of the lease by
                                offering to pay the pre-determined “residual”
                                value. Terms, residuals and rental frequency can
                                be structured to suit a client's specific
                                cashflow requirements, or to maximize tax
                                benefits during the lease period. Lease payments
                                are usually fixed at the beginning of the Lease
                                term, although variable rate facilities may be
                                available for facilities over $1 million.
                            </p>
                            <p className="breaker">
                                <Link to="/quote" className="btn">
                                    Get a Quote{' '}
                                    <CircleChevronRightFill
                                        strokeWidth={2}
                                        size={35}
                                    />
                                </Link>
                            </p>
                        </div>
                        {/* <div className="item">
                            <div className="item__left">
                                <h1>Finance Lease</h1>
                            </div>
                            <div className="item__right">
                                <p>
                                    This is the most common form of Lease
                                    agreement, usually preferred by private
                                    companies who like to acquire the asset at
                                    the end of the lease by offering to pay the
                                    pre-determined “residual” value. Terms,
                                    residuals and rental frequency can be
                                    structured to suit a client's specific
                                    cashflow requirements, or to maximize tax
                                    benefits during the lease period. Lease
                                    payments are usually fixed at the beginning
                                    of the Lease term, although variable rate
                                    facilities may be available for facilities
                                    over $1 million.
                                </p>
                                <Link to="/quote" className="btn">
                                    Get a Quote{' '}
                                    <CircleChevronRightFill
                                        strokeWidth={2}
                                        size={35}
                                    />
                                </Link>
                            </div>
                        </div> */}
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="fwitem">
                            <h1>Operating Lease</h1>
                            <p>
                                Larger corporates and Government departments
                                favour Operating Leases as they are treated as
                                an “operating” rather than a “capital” expense.
                                i.e. equipment can be acquired against an
                                operating budget without the need to obtain
                                capital expenditure approval. Both the asset and
                                liability remain off the balance sheet, which
                                leaves gearing ratios in tact.
                            </p>
                            <p className="breaker">
                                <Link to="/quote" className="btn">
                                    Get a Quote{' '}
                                    <CircleChevronRightFill
                                        strokeWidth={2}
                                        size={35}
                                    />
                                </Link>
                            </p>
                        </div>
                        {/* <div className="item">
                            <div className="item__left">
                                <h1>Operating Lease</h1>
                            </div>
                            <div className="item__right">
                                <p>
                                    Larger corporates and Government departments
                                    favour Operating Leases as they are treated
                                    as an “operating” rather than a “capital”
                                    expense. i.e. equipment can be acquired
                                    against an operating budget without the need
                                    to obtain capital expenditure approval. Both
                                    the asset and liability remain off the
                                    balance sheet, which leaves gearing ratios
                                    in tact.
                                </p>
                                <Link to="/quote" className="btn">
                                    Get a Quote{' '}
                                    <CircleChevronRightFill
                                        strokeWidth={2}
                                        size={35}
                                    />
                                </Link>
                            </div>
                        </div> */}
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="fwitem">
                            <h1>Chattel Mortgage/Hire Purchase</h1>
                            <p>
                                Chattel Mortgage and Hire Purchase (also known
                                as Asset Purchase) are usually preferred by
                                private companies who like to ultimately own
                                their equipment. These facilities often suit
                                cashflow as a GST input credit is still
                                available even though the asset has not been
                                fully paid for. Fixed or variable rate
                                structures are available over terms of up to ten
                                years. Interest rates are usually fixed at the
                                beginning of the term, although variable rate
                                facilities may be available for facilities over
                                $1 million.
                            </p>
                            <p className="breaker">
                                <Link to="/quote" className="btn">
                                    Get a Quote{' '}
                                    <CircleChevronRightFill
                                        strokeWidth={2}
                                        size={35}
                                    />
                                </Link>
                            </p>
                        </div>
                        {/* <div className="item">
                            <div className="item__left">
                                <h1>Chattel Mortgage/Hire Purchase</h1>
                            </div>
                            <div className="item__right">
                                <p>
                                    Chattel Mortgage and Hire Purchase (also
                                    known as Asset Purchase) are usually
                                    preferred by private companies who like to
                                    ultimately own their equipment. These
                                    facilities often suit cashflow as a GST
                                    input credit is still available even though
                                    the asset has not been fully paid for. Fixed
                                    or variable rate structures are available
                                    over terms of up to ten years. Interest
                                    rates are usually fixed at the beginning of
                                    the term, although variable rate facilities
                                    may be available for facilities over $1
                                    million.
                                </p>
                                <Link to="/quote" className="btn">
                                    Get a Quote{' '}
                                    <CircleChevronRightFill
                                        strokeWidth={2}
                                        size={35}
                                    />
                                </Link>
                            </div>
                        </div> */}
                    </motion.div>
                </div>
            </div>
            <div className="app-img">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="testi">
                            <div className="testi__item">
                                <div>
                                    <h2>
                                        Quokka Finance{' '}
                                        <span>
                                            <em>Plant and Equipment Finance</em>
                                        </span>
                                    </h2>
                                    <p>
                                        Quokka Finance can handle any Plant and
                                        Equipment Finance needs.
                                    </p>
                                </div>
                            </div>
                            <Image
                                src="equipment.png"
                                alt="Quokka Finance Plant Equipment Finance"
                            />
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="app-titlebr">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="apptitle" id="vehicles">
                            <div className="apptitle__txt">
                                <h2>Vehicles</h2>
                            </div>
                            <div className="apptitle__img">
                                <Image src="sedan.png" alt="" />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="app-vehicles">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="fwitem">
                            <p>
                                Your car purchase isn't just about business -
                                it's personal. Quokka Finance will personalise
                                your car finance facility to suit your budget,
                                cashflow, car allowance or any other
                                circumstance.
                            </p>
                            <p>
                                Our range of vehicle finance solutions covers
                                all requirements from salaried employees to
                                major fleet operators such as Hertz, Europcar
                                and Budget.
                            </p>
                            <p>
                                Vehicle Finance products provided by Quokka
                                Finance powered by Quantum Business Finance are:
                            </p>
                            <ul>
                                <li>
                                    Finance Lease - non maintained or with the
                                    option of full maintenance
                                </li>
                                <li>
                                    Operating Lease - with or without
                                    maintenance
                                </li>
                                <li>
                                    Novated Lease, and other salary sacrifice
                                    arrangements such as Car Allowance
                                </li>
                                <li>Hire Purchase, and</li>
                                <li>Chattel Mortgage.</li>
                            </ul>
                            <p className="breaker">
                                <Link to="/quote" className="btn">
                                    Get a Quote{' '}
                                    <CircleChevronRightFill
                                        strokeWidth={2}
                                        size={35}
                                    />
                                </Link>
                            </p>
                            <p>
                                Additional client services available through our
                                partners are:
                            </p>
                            <ul>
                                <li>
                                    Vehicle Purchasing - get the benefit of our
                                    buying power
                                </li>
                                <li>
                                    Vehicle Insurance at highly competitive
                                    pricing.
                                </li>
                            </ul>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="app-img">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="testi">
                            <div className="testi__item">
                                <div>
                                    <h2>Vehicle Finance</h2>
                                    <p>
                                        Quokka Finance will personalise your car
                                        finance facility to suit your budget,
                                        cashflow, car allowance or any other
                                        circumstance.
                                    </p>
                                </div>
                            </div>
                            <Image
                                src="qu-vehicles-mining.png"
                                alt="Quokka Finance Vehicle Finance"
                            />
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="app-titlebr">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="apptitle" id="import">
                            <div className="apptitle__txt">
                                <h2>Import/Escrow</h2>
                            </div>
                            <div className="apptitle__img">
                                <Image src="import.png" alt="" />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="app-import">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="fwitem">
                            <p>
                                For Goods purchased Overseas, Quokka Finance
                                powered by Quantum Business Finance has the
                                facilities and experience to do foreign currency
                                equipment transactions. Instead of being moved
                                from one department to another by your bank, all
                                of your requirements can be expertly managed by
                                your Quokka Finance consultant.
                            </p>
                            <p>
                                Once your Equipment has been delivered or
                                commissioned, we can roll these facilities into
                                a Lease, Chattel Mortgage or Hire Purchase
                                Agreement on the best available terms.
                            </p>
                            <p>
                                For Goods purchased within Australia, we can
                                finance deposits and progress payments as
                                required. This is very handy for machinery which
                                is specifically manufactured to your
                                requirements, or ongoing projects such as major
                                office or factory fit-outs.
                            </p>
                            <p>
                                For more details, talk to us at Quokka Finance
                            </p>
                            <p className="breaker">
                                <Link to="/quote" className="btn">
                                    Get a Quote{' '}
                                    <CircleChevronRightFill
                                        strokeWidth={2}
                                        size={35}
                                    />
                                </Link>
                            </p>
                            <p>
                                With our customary efficiency, we can arrange
                                equipment finance transactions involving any of
                                the following requirements:
                            </p>
                            <ul>
                                <li>Irrevocable Letters of Credit</li>
                                <li>SWIFT transfers in any major currency</li>
                                <li>
                                    Payment of deposits and progress payments to
                                    overseas suppliers
                                </li>
                                <li>
                                    The option of forward exchange rate cover
                                </li>
                                <li>
                                    The further option of forward interest rate
                                    cover
                                </li>
                                <li>
                                    Payment of import duty and GST upon arrival
                                </li>
                                <li>
                                    Withholding of supplier retention payment
                                </li>
                            </ul>
                        </div>
                    </motion.div>
                </div>
            </div>
        </Layout>
    );
};

export default EquipmentFinancePage;
